<template>
  <div>
    <v-dialog width="600" v-model="dialog.flag">
      <v-card>
        <v-card-title class="primary_2 d-flex">
          <p class="text-h6 white--text">
            Rejection Reason
          </p>
          <v-spacer></v-spacer>
          <v-btn fab text small @click="dialog.flag = false">
            <v-icon large color="white">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="addAlternateMobile()"
          >
            <!-- <v-textarea
              v-model="rejection_reason"
              outlined
              auto-grow
              rows="1"
              row-height="15"
              class="mt-4"
              required
              label="Enter rejection reason"
              :rules="$rules.REQURIED_FILED"
            >
            </v-textarea> -->
            <!-- <v-text-field
              v-model="rejection_reason"
              outlined
              dense
              class="mt-4"
              required
              label="Enter rejection reason"
              :rules="$rules.REQURIED_FILED"
            >
            </v-text-field> -->
            <!-- </v-form> -->

            <v-container class="px-0 pb-3" fluid>
              <v-combobox
                v-model="rejection_reason"
                :items="reason_list"
                :loading="reason_loading"
                :search-input.sync="reason_search"
                clearable
                dense
                filled
                hide-details
                required
                :rules="$rules.REQURIED_FILED"
                item-text="rejection_reason"
                item-value="rejection_reason"
                label="Select reason"
                outlined
                placeholder="Select reason"
                @focus="getReasonList"
                @keyup="getReasonList"
              >
                <!-- @change="getCustomerList" -->
                <template v-slot:append-item>
                  <div v-intersect="endIntersect"></div>
                </template>
              </v-combobox>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="mt-0 pt-0 pr-6 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            dark
            class="red"
            :loading="btn_loader"
            :disabled="btn_loader"
            @click="
              dialog.reject_pre_verification
                ? rejectPreVerification()
                : rejectDocument()
            "
          >
            Reject
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["dialog"],
  data() {
    return {
      btn_loader: false,
      rejection_reason: "",
      valid: true,

      reason: "",
      reason_list: [],
      reason_loading: false,
      reason_page: 1,
      reason_search: "",
    };
  },
  computed: {
    ...mapGetters({
      active_loan_id: "pre_verification/getActiveLoanId",
    }),
  },
  methods: {
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        // this.reason_page++;
        this.getReasonList(true);
      }
    },
    rejectPreVerification() {
      if (this.$refs.form.validate()) {
        const self = this;
        self.btn_loader = true;

        let form = new FormData();
        form.append("precheck_status", "Rejected By Cashpo");
        form.append("loan_id", self.active_loan_id);
        form.append("rejection_reason", self.rejection_reason.rejection_reason);

        const successHandler = (response) => {
          self.dialog.flag = false;
          self.$emit("closeDialog");
        };
        const finallyHandler = () => {
          self.btn_loader = false;
        };
        self.POST_request(
          self,
          self.$urls.UPDATE_PRE_VERIFICATION_STATUS,
          form,
          successHandler,
          null,
          null,
          finallyHandler
        );
      }
    },
    getReasonList(append_reason = false) {
      const self = this;
      self.reason_loading = true;
      let params = {
        reason_search_field: this.reason_search,
        page_number: this.reason_page,
      };
      console.log(params);
      const successHandler = (response) => {
        self.reason_list = response.data.result;
      };
      const finallyHandler = () => {
        self.reason_loading = false;
      };

      self.request_GET(
        self,
        self.$urls.REJECTION_REASON,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    rejectDocument() {
      if (this.$refs.form.validate()) {
        const self = this;
        self.btn_loader = true;

        let form = new FormData();
        form.append("document_id", self.dialog.document_id);
        form.append("document_status", "rejected");
        form.append("document_type", self.dialog.document_type);
        form.append("rejection_reason", self.rejection_reason.rejection_reason);

        const successHandler = (response) => {
          if (response.data.success) {
            self.dialog.flag = false;
            if (self.dialog.document_type == "bank_statement") {
              self.$emit("recallBankDetails");
            } else {
              self.$emit("recallAddressDetails");
            }
          }
        };
        const finallyHandler = () => {
          self.btn_loader = false;
        };
        self.request_POST(
          self,
          self.$urls.NEW_CUSTOMER_DOCUMENT_VERIFICAION,
          form,
          successHandler,
          null,
          null,
          finallyHandler
        );
      }
    },
  },
};
</script>
<style scoped>
#app > div.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active.v-autocomplete__content {
  top: 100px !important;
}
</style>
